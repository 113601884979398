<template>
  <div class="patrol_main_box wh100 pr">
    <HnContent :hasData="hasData">
      <template slot="left">
        <HnBaseMenu @change="areaChange"></HnBaseMenu>
      </template>
      <template slot="right">
        <div class="right_box">
          <transition name="fade">
            <div class="content_box wh100 video-box-bg-image" v-if="isVideoShow == true">
              <div class="tools_box">
                <div style="display: flex; align-items: center">
                  <el-select class="mr-20" v-model="devActiveId" size="small" style="width: 200px; margin-right: 30px" @change="devChange" placeholder="请选择视频设备">
                    <el-option v-for="item in devOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                  <HnDateSearch v-if="areaActiveId" :isPage="true" @update="dateUpdate" />
                </div>
                <i class="iconfont icon-shezhi1" @click="isVideoShow = false"></i>
              </div>
              <div class="sub_content_box" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(40, 53, 63, 0.8)" v-loading="picListLoading">
                <ul class="pic_list_box">
                  <template v-if="picList.length">
                    <li v-for="(item, i) of picList" :key="i" class="infinite-list-item pr cp">
                      <el-image style="width: 100%; height: 100%" :src="item.filePath" :preview-src-list="[item.filePath]" fit="cover">
                        <div slot="error" class="image-slot boxBg">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <p>{{ item.photoTime || "无日期" }}</p>
                    </li>
                    <template v-for="(ele, index) of supplyBox">
                      <li class="supplyBox" :key="index + 'qwe'"></li>
                    </template>
                  </template>
                  <template v-else>
                    <li class="not_data_box wh100">
                      <img :src="noDataUrl" alt="" />
                      <p>暂无图片</p>
                    </li>
                  </template>
                </ul>
                <div class="pages_box" v-if="isPageShow">
                  <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="picFromInfo.pageIndex"
                    :page-size="15"
                    layout="total, prev, pager, next"
                    class="pageInfo"
                    background
                    :total="picTotal"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <Table v-if="isVideoShow == false" :areaId="areaActiveId" @closeTable="isVideoShow = true"></Table>
          </transition>
        </div>
      </template>
    </HnContent>
  </div>
</template>

<script>
  import { completionRow } from "@/libs/util";
  import menuFun from "@/mixins/menuFun";
  import Table from "./components/table.vue";
  export default {
    components: {
      Table,
    },
    mixins: [menuFun],
    data() {
      return {
        noDataUrl: require("@/assets/imgs/noData/no_data2.png"),
        areaActiveId: "",
        devActiveId: "",
        picFromInfo: {
          pageSize: 15,
          pageIndex: 1,
          timeType: 0,
          sTime: "",
          eTime: "",
        },
        picTotal: 0,
        picList: [],
        devOption: [],
        picListLoading: false,
        isVideoShow: true,
      };
    },
    computed: {
      supplyBox() {
        let supplyArr = completionRow(this.picList, 5);
        return supplyArr || [];
      },
      isPageShow() {
        return this.picTotal > 14 ? true : false;
      },
    },
    methods: {
      // 区域切换
      areaChange(infos) {
        let data = infos[0];
        if (data) {
          this.isVideoShow = true;
          let { areaId } = data;
          this.areaActiveId = areaId;
          this.reqAreaDeviceList();
        }
      },
      dateUpdate(data) {
        Object.assign(this.picFromInfo, data);
        this.reqRegionIotDevicePhotoPageList();
      },
      // 设备切换
      devChange() {
        this.reqRegionIotDevicePhotoPageList();
      },
      // 查看视频设备图片
      async reqRegionIotDevicePhotoPageList() {
        const data = Object.assign({}, this.picFromInfo, { devId: this.devActiveId });
        this.picListLoading = true;
        const res = await this.$api.RegionIotDevicePhotoPageList(data);
        setTimeout(() => {
          this.picListLoading = false;
        }, 500);
        if (res && res.list.length) {
          this.picTotal = res.totalCount;
          this.picList = res.list;
        } else this.picList = [];
      },
      // 获取视频设备列表
      async reqAreaDeviceList() {
        const res = await this.$api.AreaDeviceList({ id: this.areaActiveId });
        if (res && res.length) {
          this.devOption = res;
          this.devActiveId = res[0].id;
          this.reqRegionIotDevicePhotoPageList();
        } else {
          this.picList = [];
          this.devOption = [];
          this.devActiveId = "";
          this.picTotal = 0;
        }
      },
      // 分页
      handleCurrentChange(page) {
        this.reqRegionIotDevicePhotoPageList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .patrol_main_box {
    .right_box {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .content_box {
      border-radius: 8px;
      box-sizing: border-box;
      padding: 40px 40px 20px 40px;
      .tools_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > i {
          font-size: 25px;
        }
        & > i:hover {
          cursor: pointer;
          color: var(--el-color-primary);
        }
      }
      .sub_content_box {
        height: calc(100% - 36px);
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url("../../../assets/imgs/control/default/decoration_bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        .pic_list_box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-content: flex-start;
          overflow: hidden;
          .not_data_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            & > p {
              margin-top: 15px;
            }
          }
          & > li.infinite-list-item {
            width: 290px;
            height: 200px;
            margin-top: 10px;
            .el-image ::v-deep .image-slot {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
            }
          }
          .infinite-list-item {
            box-sizing: border-box;
            border: 1px solid transparent;
            & > p {
              font-size: 12px;
              text-align: center;
              padding: 5px 0;
              width: 100%;
              background: rgba(0, 0, 0, 0.7);
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
          .supplyBox {
            width: 290px;
            height: 200px;
            background-color: transparent !important;
          }
        }
        .pages_box {
          margin: 10px 0;
          text-align: center;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
