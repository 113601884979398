<template>
  <div class="table wh100 video-box-bg-image">
    <div class="table_top">
      <div @click="closeTable">
        <span class="el-icon-back"></span>
        <span>设置</span>
      </div>
    </div>
    <div class="table_box" v-loading="tbaleLoading" element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(40, 53, 63, 0.8)">
      <div class="table_box_left">
        <div class="input_btn">
          <div class="pr">
            <input type="text" placeholder="请输入监控序列号或名称" v-model="inputVal" />
            <i class="el-icon-error pa close_icon" v-if="inputVal" @click="closeIput"></i>
          </div>
          <button @click="checkIuput">查询</button>
        </div>
        <ul v-if="devList && devList.length" class="wh100 boxScroll menu_box">
          <li v-for="(item, i) in devList" :key="item.id"
            :class="['site-menu-item', 'cp', isActiveMenu(item.id) ? 'menu-active-bg-image' : 'menuItemBg']"
            @click="devSelectChange(item)">
            <span :class="['iconfont', item.iconCls || 'icon-qiuji', isActiveMenu(item.id) ? 'baseColor' : '']"></span>
            <div :class="[isActiveMenu(item.id) ? 'baseColor' : '']">
              <p class="sinleline">{{ item.name }}</p>
              <p :class="[isActiveMenu(item.id) ? 'baseColor' : '']">{{ item.code }}</p>
            </div>
            <el-tooltip class="item" effect="dark" :content="netModelName(item)" placement="top">
              <span :class="['iconfont', netModelHanlde(item)]"></span>
            </el-tooltip>
          </li>
        </ul>
        <div class="mt100" v-else>
          <hn-emptyData text="暂无设备菜单" size="160px" setType="col"></hn-emptyData>
        </div>
      </div>
      <div class="table_box_right">
        <div v-if="activeObj">
          <div class="right_top">
            <div>
              <span :class="['iconfont', activeObj.iconCls || 'icon-qiuji']" class="baseColor"></span>
              <span class="baseColor">{{ activeObj.name }} - 设置</span>
            </div>
            <!-- <el-button class="but_base but_text_bot" size="small" plain @click="addConfigured">新增</el-button> -->
          </div>
          <div class="right_table">
            <div class="form">
              <el-form :model="model" :rules="rules" ref="ctlForm" size="small" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="拍照模式" prop="photoType">
                  <el-radio-group v-model="model.photoType" @input="handleCheckRadio">
                    <el-radio :label="item.value" border v-for="item of photoTypes" :key="item.value">{{ item.label
                      }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="有效日期" prop="dataSlot">
                  <div class="termTime_box">
                    <el-date-picker v-model="model.dataSlot" type="daterange" align="right" value-format="yyyy-MM-dd"
                      format="yyyy 年 MM 月 dd 日" unlink-panels range-separator="至" start-placeholder="开始日期"
                      end-placeholder="结束日期" :clearable="false" @change="timeChange('dayBut')">
                    </el-date-picker>
                    <el-button :type="butStutas.dayBut" @click="allDay" size="mini" class="allDay_but" plain
                      icon="el-icon-check">长期执行</el-button>
                  </div>
                </el-form-item>
                <el-form-item label="有效时间" prop="timeSlot">
                  <div class="termTime_box">
                    <el-time-picker v-model="model.timeSlot" value-format="HH:mm" format="HH:mm" range-separator="至"
                      start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" :clearable="false" is-range
                      @change="timeChange('timeBut')">
                    </el-time-picker>
                    <el-button :type="butStutas.timeBut" @click="allTimes" size="mini" class="allDay_but" plain
                      icon="el-icon-check">全天</el-button>
                  </div>
                </el-form-item>
                <el-form-item label="时间选择" prop="week">
                  <week v-model="model.week"></week>
                </el-form-item>
                <el-form-item label="执行频率" prop="loopTime" key="loopTime">
                  <el-tooltip class="item" effect="dark" :content="timeTooltipContent" placement="top">
                    <el-input placeholder="请输入时间" v-model.number="model.loopTime" type="number"
                      class="input-with-select" style="width: 350px">
                      <el-select v-model="mhTime" slot="prepend" placeholder="请选择" @change="timeTypeChange"
                        style="width: 80px">
                        <el-option label="分" value="m"></el-option>
                        <el-option label="小时" value="h"></el-option>
                      </el-select>
                      <span slot="suffix" class="unit_box">{{ mhTime == "h" ? "小时" : "分钟" }}</span>
                    </el-input>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="预置点位" prop="pointId" v-if="model.photoType == 3">
                  <el-select v-model="model.pointId" placeholder="请选择预置点位" multiple class="point_select">
                    <el-option v-for="item in precutList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态">
                  <el-switch v-model="model.isEnabled" :active-color="$themeColor" inactive-color="#343F48">
                  </el-switch>
                  <span :class="['switch_text', model.isEnabled ? 'baseColor' : '']">{{ model.isEnabled ? "启用" : "停用"
                    }}</span>
                </el-form-item>
                <el-form-item class="but_box">
                  <el-button type="primary" plain @click="submitForm" icon="el-icon-check"
                    style="width: 100px">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="mt100" v-else>
          <hn-emptyData text="暂无视频配置" size="260px" setType="col"></hn-emptyData>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import cronMixin from "@/mixins/cronMixin.js";
import CollapseTransition from "@/libs/collapse-transition";
import { mapTo } from "@/libs/util";
import week from "@/components/localComponent/week.vue";
export default {
  mixins: [cronMixin],
  props: {
    areaId: String,
  },
  components: {
    "collapse-transition": CollapseTransition,
    week,
  },
  data () {
    let that = this;
    const nowDate = that.$day().format("YYYY-MM-DD");
    return {
      inputVal: "",
      isActiveDevId: "",
      isActiveDevType: "",
      activeObj: null,
      configuredData: null,
      devList: [],
      onlineData: config.devStatus.online,
      tbaleLoading: false,
      model: {
        id: "",
        devId: "",
        photoCorn: "",
        isEnabled: true,
        bTime: "",
        eTime: "",
        bDate: "",
        eDate: "",
        photoType: 1,
        pointId: [],
        week: [],
        loopTime: "",
        dataSlot: [nowDate, "2099-01-01"],
        timeSlot: ["00:00", "23:59"],
      },
      nowDate: nowDate,
      mhTime: "m",
      timeTooltipContent: "请输入5 ~ 59的分钟范围",
      precutList: [],
      butStutas: {
        dayBut: "primary",
        timeBut: "primary",
      },
    };
  },
  watch: {
    areaId: {
      handler (val) {
        if (val) {
          this.reqAreaDeviceList();
        }
      },
      immediate: true,
    },
  },
  computed: {
    isActiveMenu () {
      return (id) => {
        return this.isActiveDevId == id ? true : false;
      };
    },
    netModelHanlde () {
      return (item) => {
        const netIcon = config.netModel;
        if (netIcon && typeof netIcon === "object") {
          let icon = netIcon[item.netModel] ? netIcon[item.netModel].icon : "icon-WIFIxinhao-ji";
          let color = item.onlineStatus == this.onlineData ? "baseColor" : item.onlineStatus == 2 ? "standbyColor" : "offLineColor";
          return `${icon} ${color}`;
        }
      };
    },
    netModelName () {
      return (item) => {
        const netIcon = config.netModel;
        if (netIcon && typeof netIcon === "object") {
          let title = netIcon[item.netModel] ? netIcon[item.netModel].title : "";
          let status = item.onlineStatus == this.onlineData ? "在线" : item.onlineStatus == 2 ? "待机" : "离线";
          return title ? `${title} - ${status}` : status;
        }
      };
    },
    photoTypes () {
      let list = this.$dictionary("photoTypes");
      if (this.isActiveDevType == "100402") return list;
      else {
        let slicedArr = list.slice(0, 1);
        return slicedArr;
      }
    },
    rules () {
      let timeRange = (rule, value, callback) => {
        // if (!value) {
        //   callback(new Error("请输入执行频率"));
        // }
        if (this.mhTime == "m") {
          if (value >= 5 && value <= 59) {
            callback();
          } else {
            callback(new Error("请输入5 ~ 59的分钟范围"));
          }
        } else {
          if (value >= 1 && value <= 23) {
            callback();
          } else {
            callback(new Error("请输入1 ~ 23的小时范围"));
          }
        }
      };
      const arrayEmpty = (rule, value, callback) => {
        if (!Array.isArray(value)) callback(new Error("数据格式为数组"));
        if (value.length) {
          callback();
        } else {
          callback(new Error("请选择日期"));
        }
      };
      let photoType = this.model.photoType;
      if (photoType == 3) {
        return {
          timeSlot: [{ required: true, message: "请选择有效日期期", trigger: "change" }],
          dataSlot: [{ required: true, message: "请选择有效时间", trigger: "change" }],
          photoType: [{ required: true, message: "请选择拍照模式", trigger: "change" }],
          pointId: [{ required: true, message: "请选择预置点位", trigger: "change" }],
          loopTime: [{ validator: timeRange, trigger: "change", required: true }],
          week: [{ validator: arrayEmpty, trigger: "change", required: true }],
        };
      } else {
        return {
          timeSlot: [{ required: true, message: "请选择有效日期期", trigger: "change" }],
          dataSlot: [{ required: true, message: "请选择有效时间", trigger: "change" }],
          photoType: [{ required: true, message: "请选择拍照模式", trigger: "change" }],
          loopTime: [{ validator: timeRange, trigger: "change", required: true }],
          week: [{ validator: arrayEmpty, trigger: "change", required: true }],
        };
      }
    },
  },
  methods: {
    // 关闭设置
    closeTable () {
      this.$emit("closeTable");
    },
    // 选择设置
    devSelectChange (item) {
      this.activeObj = item;
      this.isActiveDevId = item.id;
      this.isActiveDevType = item.devChildTypeId;
      this.reqGetPrecutList();
      this.reqIotVideoPhotoSetList();
    },
    // 查询
    checkIuput () {
      this.reqAreaDeviceList();
    },
    // 重置
    closeIput () {
      this.inputVal = "";
      this.reqAreaDeviceList();
    },

    // 获取视频设备
    async reqAreaDeviceList () {
      this.tbaleLoading = true;
      const res = await this.$api.AreaDeviceList({ id: this.areaId, name: this.inputVal });
      if (res && res.length) {
        this.devList = res;
        this.isActiveDevId = res[0].id;
        this.isActiveDevType = res[0].devChildTypeId;
        this.activeObj = res[0];
        this.reqGetPrecutList();
        this.reqIotVideoPhotoSetList();
      } else {
        this.devList = [];
        this.isActiveDevId = "";
        this.isActiveDevType = "";
        this.activeObj = null;
      }
      setTimeout(() => {
        this.tbaleLoading = false;
      }, 500);
    },

    // 获取视频设备配置
    async reqIotVideoPhotoSetList () {
      let list = await this.$api.IotVideoPhotoSetList({ devId: this.isActiveDevId });
      if (list && list.length) {
        mapTo(list[0], this.model);
      } else {
        this.model = {
          id: "",
          devId: "",
          photoCorn: "",
          isEnabled: true,
          bTime: "00:00",
          eTime: "23:59",
          bDate: this.nowDate,
          eDate: "2099-01-01",
          photoType: 1,
          pointId: [],
          week: [],
          loopTime: "",
          dataSlot: [this.nowDate, "2099-01-01"],
          timeSlot: ["00:00", "23:59"],
        };
      }
      this.analysisCron();
    },

    // 获取预置点位列表
    async reqGetPrecutList () {
      const res = await this.$api.GetPrecutList({ devId: this.isActiveDevId });
      this.precutList = res || [];
    },

    // 拍照模式切换
    handleCheckRadio () {
      this.model.pointId = null;
    },

    // 执行频率切换
    timeTypeChange (val) {
      if (val == "m") {
        this.model.loopTime = null;
        this.timeTooltipContent = "请输入5 ~ 59的分钟范围";
      } else {
        this.model.loopTime = null;
        this.timeTooltipContent = "请输入1 ~ 23的小时范围";
      }
    },

    // 整合数据
    mergeData () {
      this.weeksDataToCron(this.model.week);
      this.MHDataToCron(this.model.loopTime, this.mhTime);
      let [bTime, eTime] = this.model.timeSlot;
      let [bDate, eDate] = this.model.dataSlot;
      this.model.photoCorn = this.cronStr;
      this.model.bDate = bDate;
      this.model.eDate = eDate;
      this.model.bTime = bTime;
      this.model.eTime = eTime;
      this.model.devId = this.isActiveDevId;
    },
    // 解析数据
    analysisCron () {
      let cron = this.model.photoCorn;
      if (cron) {
        let week = this.weeksCronToData(cron);
        let loopTimeData = this.MHCronToData(cron);
        this.$set(this.model, "week", week);
        if (loopTimeData) {
          this.$set(this.model, "loopTime", loopTimeData.data);
          this.mhTime = loopTimeData.type;
        }
      }
      this.model.dataSlot = [this.model.bDate, this.model.eDate];
      this.model.timeSlot = [this.model.bTime, this.model.eTime];
      // 日期显示
      let eT = this.model.eTime.slice(0, 5);
      let bT = this.model.bTime.slice(0, 5);
      let eD = this.model.eDate.slice(0, 10);
      if (eT !== "23:59" && bT !== "00:00") this.butStutas.timeBut = "";
      else this.butStutas.timeBut = "primary";
      if (eD !== "2099-01-01") this.butStutas.dayBut = "";
      else this.butStutas.dayBut = "primary";
    },

    //清除按钮选中效果
    timeChange (name) {
      this.butStutas[name] = "";
    },

    // 全天
    allTimes () {
      this.$set(this.model, "timeSlot", ["00:00", "23:59"]);
      this.butStutas.timeBut = "primary";
    },

    // 长期执行
    allDay () {
      this.model.dataSlot = [this.nowDate, "2099-01-01"];
      this.butStutas.dayBut = "primary";
    },

    // 保存
    submitForm () {
      this.$refs.ctlForm.validate((valid) => {
        if (valid) {
          this.mergeData();
          let api = this.model.id ? "EditIotVideoPhotoSet" : "AddIotVideoPhotoSet";
          this.$api[api](this.model).then((res) => {
            if (res) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px 40px 20px 40px;

  &_top {
    display: flex;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    div:hover>span:first-child {
      color: #fff;
    }

    div>span:first-child {
      display: inline-block;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      background-color: var(--el-color-primary);
      color: #15232d;
      font-size: 20px;
      margin-right: 5px;
    }

    div>span:last-child {
      display: inline-block;
      color: var(--el-color-primary);
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_box {
    display: flex;
    margin-top: 10px;

    &_left {
      width: 321px;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;

      .input_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 1;

        input {
          width: 145px;
          height: 30px;
          background: rgba(153, 153, 153, 0.1);
          border: 1px solid #999999;
          border-radius: 5px;
          font-size: 12px;
          color: #ffffff;
          outline: none;
        }

        input[type="text"] {
          padding-left: 10px;
          padding-right: 25px;
        }

        input::placeholder {
          color: #ccc;
        }

        .close_icon {
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }

        button {
          cursor: pointer;
          font-size: 14px;
          color: #ffffff;
          width: 60px;
          height: 30px;
          background: rgba(153, 153, 153, 0.1);
          border: 1px solid #999999;
          border-radius: 5px;
        }
      }

      .menu_box {
        box-sizing: border-box;
        padding-right: 10px;
        margin-top: 10px;
        height: 650px;
      }

      .site-menu-item {
        width: 100%;
        height: 60px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &>span {
          display: block;

          flex-shrink: 0;
        }

        &>span:first-child {
          width: 30px;
          font-size: 30px;
          margin: 0 8px;
        }

        &>span:last-child {
          font-size: 18px;
          width: 24px;
          margin: 0 8px;
        }

        &>div {
          flex: 1;
          min-width: 0;

          // max-width: 170px;
          &>p:last-child {
            width: 100%;
            color: #dcdfe6;
            font-size: 12px;
          }
        }
      }

    }

    &_right {
      height: 579px;
      flex: 1;
      margin-left: 20px;

      .right_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        div {
          display: flex;
          align-items: center;
        }

        div>span:first-child {
          font-size: 30px;
        }

        div>span:last-child {
          font-size: 16 px;
          display: inline-block;
          margin-left: 5px;
        }
      }

      .right_table {
        display: flex;
        justify-content: center;

        .form {
          margin-top: 20px;
          box-sizing: border-box;

          .demo-ruleForm {
            width: 100%;

            .but_box {
              margin-top: 40px;
              text-align: center;
              margin-bottom: 0 !important;
            }

            ::v-deep .el-radio {
              margin-right: 20px;
            }

            ::v-deep .el-switch__core {
              background: none;
              border: none;
            }
          }

          .termTime_box {
            display: flex;
            align-items: center;

            .allDay_but {
              margin-left: 10px;
            }
          }

          .input-with-select {
            ::v-deep .el-input-group__prepend {
              background: #344552;
              border: none;
              border-right: 1px solid #999999;
            }
          }

          .switch_text {
            margin-left: 12px;
            color: #999;
          }

          .unit_box {
            display: block;
            margin-top: 6px;
          }

          ::v-deep .el-select {
            width: 350px;
          }
        }
      }
    }
  }
}
</style>
