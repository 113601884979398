export default {
  data () {
    return {
      minutesText: "",
      hoursText: '',
      secondsText: '',
      weeksText: '',
    }
  },
  computed: {
    cronStr () {
      return `${this.secondsText || "0"} ${this.minutesText || "0"} ${this.hoursText || "*"} ? * ${this.weeksText || "*"}`;
    }
  },
  methods: {
    //  处理周 cron => data
    weeksCronToData (cron) {
      let val = cron.split(" ")[5];
      if (val == '?') {
        return []
      }
      if (val.includes(',')) {
        let activeWeek = val.split(',')
        return activeWeek
      }
      if (val && val != '*') {
        return [val]
      }
      return []
    },

    // 秒分时 cron => data 
    MHCronToData (cron) {
      let cronArr = cron.split(" ")
      let M = cronArr[1], H = cronArr[2];
      let result = ''
      let Hnub = 0
      if (H == '*' && M.includes("/")) {
        result = M.split("/")[1];
        return { data: parseInt(result), type: 'm' }
      }
      if (M == '0' && H.includes("/")) {
        Hnub = H.split("/")[1];
        result = parseInt(Hnub)
        return { data: result, type: 'h' }
      }
    },
    // 秒分时 data => cron
    SMHDataToCron (data) {
      if (data > 0 && data <= 59) {
        this.minutesText = `0/${data}`
        return
      }
      if (data > 59) {
        let remainderNub = data % 60
        let parseNub = parseInt(data / 60)
        if (remainderNub == 0) {
          this.minutesText = `0`
          this.hoursText = `0/${parseNub}`
        } else {
          this.minutesText = remainderNub
          this.hoursText = `0/${parseNub}`
        }
      }
    },
    MHDataToCron (data, type = 'm') {
      if (type == 'm') {
        // 转换为分   
        this.minutesText = `0/${data}`
      } else {
        // 转化为小时
        this.hoursText = `0/${data}`
      }
    },
    // 周 data => cron
    weeksDataToCron (data) {
      this.weeksText = data.join(',')
    }
  }
}